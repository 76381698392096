import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { Phone, X, Bot } from 'lucide-react';
import useLogRenders from '../../hooks/useLogRenders';
import CallNowWidget from '../CallNowWidget/CallNowWidget';
import agentCardsData from '../../agentCards.json';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import BannerRotation from '../Banner/BannerRotation'; // Import the BannerRotation component

const Home = React.memo(() => {
    useLogRenders('Home');
    const [callNowAgent, setCallNowAgent] = useState({});
    const [user, setUser] = useState(null);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const generateSessionId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          // eslint-disable-next-line no-mixed-operators
          const r = Math.random() * 16 | 0;
          // eslint-disable-next-line no-mixed-operators
          const v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    };

    const handleGoogleLoginSuccess = (credentialResponse) => {
        try {
          const decodedToken = jwtDecode(credentialResponse.credential);
          const sessionId = generateSessionId();
          const userDetails = {
            id: decodedToken.sub,
            email: decodedToken.email,
            name: decodedToken.name,
            givenName: decodedToken.given_name,
            familyName: decodedToken.family_name,
            picture: decodedToken.picture,
            sessionKey: sessionId,
          };
          localStorage.setItem('user', JSON.stringify(userDetails));
          setUser(userDetails);
          setShowLogin(false); // Hide login after successful login
        } catch (error) {
          console.error('Error processing login:', error);
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    const handleCallNowClick = useCallback((agentName) => {
        //console.log('Link clicked', agentName);
        if (user) {
            setCallNowAgent({ name: agentName, state: 'open' });
        } else {
            setShowLogin(true); // Show login if user is not logged in
            setCallNowAgent({ name: agentName, state: 'open' });
        }
    }, [user]);

    const handleSession = (userInfo) => {
        console.log("handleSession", userInfo);
        if (userInfo) {
            setUser(userInfo);
        } else {
            setUser(null); // Clear the user session if null is passed
        }
    };

    const closeLoginModal = () => {
        setShowLogin(false);
    };

    const getAgentImage = useMemo(() => {
        return (agentName) => require(`../../images/${agentCardsData.find((agent) => agent.name === agentName).imagePath}`);
    }, []);

    const renderCards = useMemo(() => {
        return agentCardsData.map((agent, index) => (
            <div className="card" key={index}>
                <div className="card-image-container">
                    <img src={getAgentImage(agent.name)} alt={agent.title} className="card-image" />
                </div>
                <div className="card-content">
                    <h3 className="card-title">
                        <Link target='_blank' to={`/call?agent=${agent.name}`}>{agent.title}</Link>
                    </h3>
                    <p className="card-description">{agent.description}</p>
                    <div className="w-100 d-flex-c">
                        <Link className="call-button" onClick={() => handleCallNowClick(agent.name)}>
                            <Phone size={14} style={{ marginRight: '0.5rem' }} />
                            Demo
                        </Link>
                        <Link className="call-button" target='_blank' to={`https://platform.rapo.ai/?subscribe=${agent.name}`}>
                            <Bot size={18} style={{ marginRight: '0.5rem' }} />
                            Hire
                        </Link>
                    </div>
                    
                </div>
            </div>
        ));
    }, [getAgentImage, handleCallNowClick]);

    const renderCallNowWidget = useMemo(() => {
        if (callNowAgent.name && user) {
            return <CallNowWidget callNowAgent={callNowAgent} setCallNowAgent={setCallNowAgent} getAgentImage={getAgentImage} user={user} />;
        }
        return null;
    }, [callNowAgent, setCallNowAgent, getAgentImage, user]);

    return (
        <div className={`home-page ${callNowAgent?.state}`}>
            <Header user={user} handleSession={handleSession} />
            <div className="banner-wrapper">
                <BannerRotation />
            </div>
            <div className="card-container">
                {renderCards}
            </div>
            <Footer />
            {renderCallNowWidget}

            {showLogin && (
                <div className="login-modal">
                    <div className="login-container">
                        <X className="close-icon" onClick={closeLoginModal} />
                        <h3>Please log in to continue</h3>
                        <GoogleLogin 
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            text="Sign in with Google"
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default Home;